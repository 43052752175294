import * as imageService from './imageService';
import { browser } from '$app/environment';

const OLD_ID_FRONT_KEY = 'id_front';
const OLD_ID_BACK_KEY = 'id_back';
const OLD_SELFIE_KEY = 'selfie';
const OLD_ADDRESS_PROOF_KEY = 'address_proof_image';

const keyMap = {
	[OLD_ID_FRONT_KEY]: imageService.ID_FRONT_KEY,
	[OLD_ID_BACK_KEY]: imageService.ID_BACK_KEY,
	[OLD_SELFIE_KEY]: imageService.SELFIE_KEY,
	[OLD_ADDRESS_PROOF_KEY]: imageService.ADDRESS_PROOF_KEY
}

/**
 * Migrates image data from the old indexedDB service to the new imageService
 * This should be called once on application startup to ensure no user data is lost
 * during the service consolidation process.
 *
 * @returns Promise<{migrated: string[], errors: string[]}>
 */
export async function migrateImageData(): Promise<{migrated: string[], errors: string[]}> {
	if (!browser) return { migrated: [], errors: [] };

	// Check if migration has already been performed
	if (localStorage.getItem('imageMigrationCompleted') === 'true') {
		console.log('Image migration already completed');
		return { migrated: [], errors: [] };
	}

	console.log('Starting image data migration...');

	// Define all image keys that need migration
	const keys = [
		OLD_SELFIE_KEY,
		OLD_ID_FRONT_KEY,
		OLD_ID_BACK_KEY,
		OLD_ADDRESS_PROOF_KEY
	];

	const migrated: string[] = [];
	const errors: string[] = [];

	// Check if new IndexedDB is available
	const indexedDbSupported = await imageService.isIndexedDBSupported();
	if (!indexedDbSupported) {
		console.error('New IndexedDB service not supported - cannot migrate');
		return { migrated, errors: ['New IndexedDB service not supported'] };
	}

	// For each key, try to migrate the image
	for (const key of keys) {
		try {
			// Check if image already exists in the new service
			const existsInNew = await imageService.hasImage(key);
			if (existsInNew) {
				console.log(`Image ${key} already exists in new service, skipping`);
				continue;
			}

			// Try to get the image from the old service
			const image = await imageService.getImage(key, true);
			if (image) {
				// Save to new service
				// @ts-expect-error - TS doesn't like the keyMap[key] lookup
				await imageService.saveImage(keyMap[key], image);
				console.log(`Successfully migrated image: ${key}`);
				migrated.push(key);
			}
		} catch (err) {
			console.error(`Error migrating ${key}:`, err);
			errors.push(key);
		}
	}

	// Mark migration as completed if at least some images were migrated
	// or if there were no errors (meaning nothing needed migration)
	if (migrated.length > 0 || errors.length === 0) {
		localStorage.setItem('imageMigrationCompleted', 'true');

		const formDataString = localStorage.getItem('formData');
		const formData = formDataString ? JSON.parse(formDataString) : null;
		if (formData) {
			for (const key of keys) {
				formData[key] = undefined;
			}

			localStorage.setItem('formData', JSON.stringify(formData));
		}
	}

	console.log('Image migration completed');
	console.log('Migrated:', migrated);
	console.log('Errors:', errors);

	return { migrated, errors };
}

/**
 * Checks if the data migration has been completed
 */
export function isMigrationCompleted(): boolean {
	if (!browser) return false;
	return localStorage.getItem('imageMigrationCompleted') === 'true';
}

/**
 * Force reset the migration status - use only for testing
 */
export function resetMigrationStatus(): void {
	if (!browser) return;
	localStorage.removeItem('imageMigrationCompleted');
}